import { Injectable } from '@angular/core';
import { SharedService } from '../../../shared/shared.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import dayjs from 'dayjs';
import { DateTimeHelper } from '../../../shared/helpers/datetime.helper';
@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  billingConfigData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  host = environment.host();
  constructor(private sharedService: SharedService, private http: HttpClient) {}
  getBillingConfig() {
    if (!this.billingConfigData.value) {
      let requestUrl =
        this.host +
        'sponsors/' +
        this.sharedService.currentSponsorId +
        '/billing-config/';
      this.http.get(requestUrl).subscribe({
        next: (data: any) => {
          this.processBillingConfigData(data[0]);
        },
      });
    } else {
      return;
    }
  }
  processBillingConfigData(data:any) {
    data.due_date = data.due_date
        ? dayjs(data.due_date).format(DateTimeHelper.dateFormat)
        : '';
    this.billingConfigData.next(data);
  }
  getInvoices(cursor: any, searchKey: any, partnerId: any) {
    cursor = cursor
      ? `&${new URL(cursor, this.host).searchParams.toString()}`
      : '';
    let requestUrl =
      this.host + 'sponsors/' + partnerId + '/invoices/?page_size=15';
    let cursorstr =
      cursor !== '' && cursor !== undefined ? '&cursor=' + cursor : '';
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    if (searchKey !== undefined && searchKey !== '') {
      requestUrl += '&search=' + searchKey;
    }
    return this.http.get(requestUrl, { observe: 'response' }).pipe(
      map((res: any) => {
        const invoiceData = res.body;
        return {
          data: invoiceData,
          next: res.headers.get('Next'),
          prev: res.headers.get('Prev'),
        };
      })
    );
  }
  getPayments(cursor: any, searchKey: any, sponsorId: any) {
    cursor = cursor
      ? `&${new URL(cursor, this.host).searchParams.toString()}`
      : '';
    let requestUrl =
      this.host + 'sponsors/' + sponsorId + '/payments/?page_size=15';
    let cursorstr =
      cursor !== '' && cursor !== undefined ? '&cursor=' + cursor : '';
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    if (searchKey !== undefined && searchKey !== '') {
      requestUrl += '&search=' + searchKey;
    }
    return this.http.get(requestUrl, { observe: 'response' }).pipe(
      map((res: any) => {
        const paymentData = res.body;
        return {
          data: paymentData,
          next: res.headers.get('Next'),
          prev: res.headers.get('Prev'),
        };
      })
    );
  }
  downloadPartnerInvoice(invoiceId: any, sponsorId: any) {
    let requestUrl =
      this.host +
      'sponsors/' +
      sponsorId +
      '/invoices/' +
      invoiceId +
      '/export/';
    return this.http.get(requestUrl);
  }
  downloadInvoiceTranscations(invoiceId: any, sponsorId: any,fileType:string){
    let requestUrl =
      this.host +
      'sponsors/' +
      sponsorId +
      '/invoices/' +
      invoiceId +
      '/bits/'+fileType+'/';
    return this.http.get(requestUrl);
  }
  getInvoiceDetails(invoiceId: any, partnerid: any) {
    let requestUrl =
      this.host + 'sponsors/' + partnerid + '/invoices/' + invoiceId + '/';
    return this.http.get(requestUrl);
  }
  getInvoiceTransactions(cursor: any, invoiceid: any, partnerid: any) {
    cursor = cursor
      ? `&${new URL(cursor, this.host).searchParams.toString()}`
      : '';
    let requestUrl =
      this.host +
      'sponsors/' +
      partnerid +
      '/invoices/' +
      invoiceid +
      '/bits/?bit_category=ACCRUAL&status=SUCCESS&ordering=-h_bit_date&page_size=15';
    let cursorstr =
      cursor !== '' && cursor !== undefined ? '&cursor=' + cursor : '';
    if (cursor !== undefined && cursor !== 'None') {
      requestUrl += cursorstr;
    }
    return this.http.get(requestUrl, { observe: 'response' }).pipe(
      map((res: any) => {
        const paymentData = res.body;
        return {
          data: paymentData,
          next: res.headers.get('Next'),
          prev: res.headers.get('Prev'),
        };
      })
    );
  }
  downloadTransactions(
    partnerId: any,
    startdate: any,
    enddate: any,
    type: any,
    bitType: any
  ) {
    let requestUrl =
      this.host.replace('v1', 'v2') +
      'bit/export/' +
      type +
      '/?sponsor_id=' +
      partnerId +
      '&date_from=' +
      startdate +
      '&date_to=' +
      enddate +
      '&status=SUCCESS&bit_category=ACCRUAL' +
      '&ordering=-h_bit_date';
    return this.http.get(requestUrl);
  }
}
